import React, {Component} from 'react';
import {Navigate} from 'react-router-dom';
import {unmountComponentAtNode} from 'react-dom';
import {base} from '../../base';
import { confirmAlert } from '../utils/react-confirm-alert';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import Confetti from 'react-confetti';
import { getDistance } from 'geolib';
import Loading from '../utils/Loading';
import appBackgroundImage from '../../styles/images/background.png'
import sampleAppFrontImage from '../../styles/images/front_icon.png'
import sampleAppTopImage from '../../styles/images/top_icon.png'
import '../../styles/css/Home.css';
import '../../styles/css/ConfirmAlertCustom.css';
import '../../styles/css/main.css';
import '../../styles/css/CustomCSS.css';
import {getRandomInt, sleep, validateEmail} from '../utils/HelpfulFunction';
import {PulseLoader as LoadingSpinner} from "react-spinners";

class Main_App extends Component {
    constructor(props) {
        super(props);
        this.state = {
          userEmail: sessionStorage.getItem('userEmail') || sessionStorage.getItem('tempToken') || false,
          active: false,
          width: 0,
          height: 0,
          alreadyUpdate: "",
          currentGameId: null,
          currentGameTime: false,
          currentResponseArray: [],
          startConfetti: false,
          rotationStarted: false,
          optedIntoEmail: {},
          tierMessaging: [],
          matches: [],
          modal: false,
          userHighScore: null,
          locationChecking: true,
          loading: true,
          alreadyPlayed: false
        };
        this.logout = this.logout.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.toggle = this.toggle.bind(this);
        this.winnerToggle = this.winnerToggle.bind(this);
        this.toggleRules = this.toggleRules.bind(this);
    }

    removeReactAlert(){
      document.body.classList.remove('react-confirm-alert-body-element');
      const target = document.getElementById('react-confirm-alert');
      if(target){
          unmountComponentAtNode(target)
        target.parentNode.removeChild(target)
      }
      const svg = document.getElementById('react-confirm-alert-firm-svg');
      if(svg){
        svg.parentNode.removeChild(svg);
        document.body.children[0].classList.remove('react-confirm-alert-blur')
      }
    }

    componentDidMount(){
      this.currentGameKeyRef = base.listenTo(`currentGame/id`, {
        context: this,
        then(key){
          if(typeof key === "string"){
            this.logUserActivity(key);
            this.removeReactAlert();
            this.restartClicked();
            this.setState({
              currentGameId:key,
              startConfetti: false
            })
          }
        }
      });

      this.tenantRulesRef = base.bindToState(`tenantRules`, {
        context: this,
        state: 'tenantRules',
      });

      this.tilesRef = base.listenTo('currentGame/tiles', {
        context: this,
        asArray: true,
        then(tilesData){
          if(tilesData){
            this.setState({
              tiles:tilesData
            })
          }
        }
      })

      this.activeRef = base.listenTo('currentGame/active', {
        context: this,
        then(dataActive){
          if(this.props.variables && this.props.variables.collectDistance && dataActive){
            this.checkUsersLocation();
          } else {
            this.setState({
              active: dataActive,
              locationChecking: false,
              loading:false
            })
          }
        }
      })

      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
      window.scrollTo(0,0);
      this.props.loadPage();
    }

    changeMilesToMeters(milesToConvert){
      return milesToConvert*1609.344;
    }

    getLocation(){
      return new Promise((resolve, reject) => {
        if (!navigator.geolocation) {
          reject("Geolocation is not supported by your browser. Please change browsers to play!");
        } else {
          const toCheckLatitude = this.props.variables.latitude || 51.525;
          const toCheckLongitude = this.props.variables.longitude || 7.4575;
          navigator.geolocation.getCurrentPosition(
              function(position) {
                resolve(getDistance({latitude: position.coords.latitude, longitude: position.coords.longitude}, {
                  latitude: toCheckLatitude,
                  longitude: toCheckLongitude,
                }))
              },
              (err) => {
                if(err.message === "User denied Geolocation"){
                  reject("Position could not be determined because the browser does not have permission.  Please go to your browsers settings to allow it access to your location");
                } else {
                  console.log(err.message);
                  reject("An unknown error occurred, check your internet connection and try again");
                }
              }
          );
        }
      })
    }

    getLocationPermission(){
      const locationErrorTitle = "Location Error";
      this.getLocation().then(distance_meters => {
        sessionStorage.setItem('locationPermissions', "true");
        const allowed_distance = this.changeMilesToMeters(this.props.variables.acceptableDistance || 100); //In miles
        if(distance_meters <= allowed_distance){
          this.setState({
            modal:false,
            loading:false,
            locationChecking: false,
            active: true
          });
        } else if(this.props.variables.canPlay) {
          this.setState({
            modal:false,
            loading:false,
            locationChecking: false,
            active: true,
            locationCheckFailed: true
          });
        } else {
          this.setState({
            modal:false,
            loading:false
          });
          confirmAlert({
            title: locationErrorTitle,
            variables: this.props.variables,
            message: "Too far from game area to participate!",
            buttons: [
              {
                label: 'Retry',
                onClick: () => {
                  this.checkUsersLocation()
                }
              }
            ]
          });
        }
      }, error => {
        console.log(error);
        this.setState({
          modal:false,
          loading:false
        });
        sessionStorage.setItem('locationPermissions', "false");
        if(typeof error != "string"){
          error = error.message
        }
        confirmAlert({
          title: locationErrorTitle,
          variables: this.props.variables,
          message: error,
          buttons: [
            {
              label: 'Retry',
              onClick: () => {
                this.checkUsersLocation()
              }
            }
          ]
        });
      })
    }

    checkUsersLocation(){
      const variables = this.props.variables;
      const locationPermissions = sessionStorage.getItem('locationPermissions');
      if(!this.props.variables.collectDistance) {
        this.setState({locationChecking: false})
      } else if(locationPermissions === "false" || !locationPermissions){
        const locationPermissionsHeader = variables.locationPermissionsHeader || "Location Permissions Required";
        const locationPermissionsBody = variables.locationPermissionsBody || "We need your location in order to play! We use this information to make your experience better";
        confirmAlert({
          title: locationPermissionsHeader,
          variables: variables,
          message: locationPermissionsBody,
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                this.setState({
                  loading: true,
                });
                this.getLocationPermission()
              }
            }
          ],
        })
      } else {
        this.setState({
          loading: true,
        });
        this.getLocationPermission()
      }
    }

    handleChange(event) {
      this.setState({[event.target.name]: event.target.value});
    }

    winnerToggle() {
      this.setState({
        winnerModal: !this.state.winnerModal,
        startConfetti: false,
        downloadedFile: null
      });
    }

    toggleRules() {
      this.setState({
        modalRules: !this.state.modalRules,
      });
    }

    componentWillUnmount() {
      base.removeBinding(this.currentGameKeyRef);
      base.removeBinding(this.tenantRulesRef);
      base.removeBinding(this.tilesRef);
      base.removeBinding(this.activeRef);
      window.removeEventListener('resize', this.updateWindowDimensions);
    }

    toggle() {
      this.setState({
        modal: !this.state.modal,
      });
    }

    updateWindowDimensions() {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    logout(){
      sessionStorage.removeItem('userEmail');
      sessionStorage.removeItem('verifiedAge');
      sessionStorage.removeItem('birthday');
      sessionStorage.removeItem('locationPermissions');
      this.setState({
        userEmail:false
      })
    }

    fireWinningOrLosingMessage(winner, tile){
      const variables = this.props.variables;
      let messageHeader = variables.losingHeader || "Sorry";
      let messageBody = variables.losingMessage || "Come back next time and try again";
      let messageImage, messageImageMessage;
      if(winner){
        this.setState({
          startConfetti: true
        })
        messageHeader = variables.winningHeader || "You Win!";
        messageBody = variables.winningMessage || "Congratulations";
        messageImage = tile.spinImage;
        messageImageMessage = tile.rewardDisplayName;
          this.sendWinningEmail();
      }
      confirmAlert({
        variables: variables,
        title: messageHeader,
        message: messageBody,
        messageImage: messageImage,
        messageImageMessage: messageImageMessage,
        buttons: [
          {
            label: 'OK',
            onClick: () => {
              this.setState({startConfetti: false})
            }
          }
        ]
      })
      if(this.state.currentGameId){
        this.logUserActivity(this.state.currentGameId);
      }
    }

    sendWinningEmail(){
        let tag = process.env.REACT_APP_FIREBASE_PROJECT_ID;
        if(process.env.NODE_ENV === "development"){
            tag = "";
        }
        const isMlb = process.env.REACT_APP_IS_MLB_TEAM === "true";
        let isSpecialEmail = process.env.REACT_APP_FIREBASE_PROJECT_ID === "americandreamspintowin";
        const requestPrizeObject = {
            email: this.state.userEmail,
            isMlb: isMlb,
            mlbId: process.env.REACT_APP_MLB_TEAM_ID,
            tag: tag,
            isSpecialEmail: isSpecialEmail
        }
        let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/releasePrizeRealTimeDb`;
        if(process.env.NODE_ENV === "development"){
            url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/releasePrizeRealTimeDb`;
        }
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestPrizeObject),
        }).then(res =>{
            Promise.resolve(res.json()).then(async function (value) {
                console.log(value);
            })
        })
    }

    logUserActivity(gameid){
      const vm = this;
      if(gameid){
        const userEmailToBase64 = btoa(this.state.userEmail);
        base.post('userGameHistory/'+userEmailToBase64+`/${gameid}` , {
          data:gameid,
          then(err){
            if(!err){
              vm.setState({
                alreadyUpdate: gameid
              })
              console.log("user game logged!")
            }
          }
        })
      }
    }

    renderHoldingScreen(){
      const tenantVariables = this.props.variables || {};
      const frontLogoImage = tenantVariables.frontLogoImage || sampleAppFrontImage;
      const logOutButtonColor = tenantVariables.logOutButtonColor || "white";
      const playingTextHeader = tenantVariables.playingTextHeader || "No Game Up";
      const playingTextBody = tenantVariables.playingTextBody || "Come back later to play";

      return(
          <>
              <div className="hero-text-container">
                <img src={frontLogoImage} className="main-hero-image" alt=""/>
              </div>
              <p style={{color:logOutButtonColor}}>
                <span className="emphasizedText">{playingTextHeader}</span>
                <br/>
                <span style={{color:logOutButtonColor}} dangerouslySetInnerHTML={{ __html:playingTextBody}}/>
              </p>
          </>
        )
    }

    checkIfWon(nextSelectedItemIndex){
        const tile = this.state.tiles[nextSelectedItemIndex];
        if(tile.id){
          this.fireWinningOrLosingMessage(true, tile);
        } else {
          this.fireWinningOrLosingMessage(false);
        }
    }

    restartClicked(){
      this.setState({
        startConfetti:false,
        alreadyPlayed: false,
        nextSelectedItemIndex: null,
        selectedItemIndex: null
      })
    }

    cycleThroughElements(){
        const variables = this.props.variables;
        const tiles = this.state.tiles || [];
        const tilesLength = tiles.length;
        if(this.state.alreadyPlayed){
          confirmAlert({
            variables: variables,
            title: "Already Played",
            message: "Can't play again at this time!",
            buttons: [
              {
                label: 'OK'
              }
            ]
          })
          return;
        }
        this.setState({
            buttonLoading: true,
            requestLoading: true
        })
        const userEmail = this.state.userEmail;
        let base64EncodedEmail = btoa(userEmail);
        if(userEmail && userEmail.indexOf('@') === -1){
          base64EncodedEmail = userEmail
        }
        const vm = this;
        let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/gameWithOddsCheckIfWins`;
        if(process.env.NODE_ENV === "development"){
            url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/gameWithOddsCheckIfWins`;
        }
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({userInfo: base64EncodedEmail}),
        }).then(res =>
            Promise.resolve(res.json()).then(async function (value) {
              vm.setState({
                  buttonLoading: false,
                  requestLoading: false
              })
              let loser = false;
              if(value.error){
                confirmAlert({
                  variables: variables,
                  title: "Unknown Error",
                  message: "Some issue occurred, check your internet connection and try again",
                  buttons: [
                    {
                      label: 'OK'
                    }
                  ]
                })
                return;
              } else if(value.result === 'already_played'){
                confirmAlert({
                  variables: variables,
                  title: "Already Played",
                  message: "Come back next time and try again!",
                  buttons: [
                    {
                      label: 'OK'
                    }
                  ]
                })
                return;
              } else if(value.result === 'lost'){
                  loser = true;
              }
              let winningIndex = value.winningIndex;
              if(loser){
                  const tiles = vm.state.tiles;
                  let losingSections = [];
                  for(const i in tiles){
                      const tile = tiles[i];
                      if(!tile.id){
                          losingSections.push(i);
                      }
                  }
                  const random = Math.floor(Math.random() * losingSections.length);
                  winningIndex = losingSections[random];
              }
              winningIndex = parseInt(winningIndex);
                vm.setState({
                cycling: true,
                alreadyPlayed: true
              })

              let limit = getRandomInt(500,750);
              let current = 1;
              let nextSelectedItemIndex = getRandomInt(0,tilesLength-1);
              let numberOfRotations = 0;
              while(current < limit || winningIndex !== nextSelectedItemIndex){
                await sleep(current);
                await vm.setState({
                  selectedItemIndex: nextSelectedItemIndex
                });
                if(numberOfRotations > 30){
                  current += 40;
                } else if(numberOfRotations > 25){
                  current += 20;
                } else if(numberOfRotations > 15) {
                  current += 10;
                } else {
                  current += 1;
                }
                if(nextSelectedItemIndex === tilesLength-1){
                  nextSelectedItemIndex = 0;
                } else {
                  nextSelectedItemIndex++;
                }
                numberOfRotations++;
              }
              //necessary to move up to final square that represents losing
                await sleep(current);
                await vm.setState({
                    selectedItemIndex: nextSelectedItemIndex
                });
                await sleep(2000)
              vm.setState({
                cycling: false
              }, () => {
                vm.checkIfWon(nextSelectedItemIndex);
              })
            })
        ).catch((e)=>{
            console.log(e);
            confirmAlert({
                variables: variables,
                title: "Unknown Error",
                message: "Check your internet connection and try again!",
                buttons: [
                    {
                        label: 'OK'
                    }
                ]
            })
            this.setState({
                buttonLoading: false
            })
        })
    }

    renderPlayingScreen(){
      const tenantVariables = this.props.variables || {};
      const topLeftImage = tenantVariables.topHeaderImage || sampleAppTopImage;
      const primaryColor = tenantVariables.primaryColor || "black";
      const secondaryColor = tenantVariables.secondaryColor || "white";
      const highLightColor = tenantVariables.tileHighLightColor || "red";
      const tiles = this.state.tiles;
      return(
          <div style={{overflowX: "hidden"}}>
              <div className="creator-awards-logo-playscreen user">
                <img src={topLeftImage} alt="" style={{margin:0}}/>
              </div>
              {/*<div className="spacer-md"/>*/}
              {/*<div className="spacer-md"/>*/}
              {/*{!this.state.cycling && !this.state.alreadyPlayed &&*/}
                  <div>
                      <button disabled={this.state.buttonLoading || (this.state.cycling || this.state.alreadyPlayed)} className="btn btn-play" style={{color: secondaryColor, backgroundColor: primaryColor}} onClick={()=>this.cycleThroughElements()}>SPIN!</button>
                      <div className="spacer-md"/>
                  </div>
              {/*}*/}
              {tiles && tiles.length > 0 &&
                <div style={{display:'flex', flexDirection:'row', flexWrap: "wrap", maxWidth: "100%", justifyContent:"center"}}>
                  {
                    tiles.map(function(item, index){
                        let activeBackgroundColor = "";
                        if(index === this.state.selectedItemIndex){
                            activeBackgroundColor = highLightColor;
                        }
                        return(
                            <div key={index} style={{display: 'flex', flexDirection: "column", justifyContent: "flex-end", alignItems: "center", maxWidth: "20%"}}>
                              {item.rewardName !== "No Prize" &&
                                  <div style={{maxWidth: 60, maxHeight: 120}}>
                                    <img width="auto" height="auto" src={item.spinImage} alt="" style={{maxWidth: "100%", maxHeight: "100%"}}/>
                                  </div>
                              }
                              <div style={{width: 60, height: 120, margin: 5, maxWidth: "100%", backgroundColor: activeBackgroundColor ? activeBackgroundColor : tenantVariables.tileBackgroundColor, color: tenantVariables.tileTextColor}}>
                                {item.rewardName === "No Prize" ?
                                    <div className="overlay">
                                      <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100%"}}>
                                          {tenantVariables.noPrizeTileText || "No Prize"}
                                      </div>
                                    </div>
                                    :
                                    <div className="overlay">
                                      <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100%"}}>
                                        {item.rewardDisplayName || item.rewardName}
                                      </div>
                                    </div>
                                }
                              </div>
                            </div>
                        )
                    }, this)
                  }
                </div>
              }
              <div style={{height:"100px", width:"1px"}}/>
          </div>
        )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if(prevState.active && !this.state.active && prevState.currentGameId === this.state.currentGameId){
        const userEmailToBase64 = btoa(this.state.userEmail);
        const gameid = this.state.currentGameId;
        base.fetch('userGameHistory/'+userEmailToBase64+`/${gameid}` , {
          context: this,
          then(data){
            if(typeof data === "object"){
              this.logUserActivity(gameid);
              this.removeReactAlert();
              this.restartClicked();
            }
          }
        })
      }
    }

    render() {
      const tenantVariables = this.props.variables || {};
      const tenantRules = this.state.tenantRules || {};
      const backgroundImage = tenantVariables.backgroundImage || appBackgroundImage;
      const verifiedAge = this.props.checkForAgeGate(tenantVariables);
      const logOutButtonColor = tenantVariables.logOutButtonColor || "white";
      const primaryColor = tenantVariables.primaryColor || "black";
      const secondaryColor = tenantVariables.secondaryColor || "white";
      const rulesInAppButtonText = tenantRules.rulesInAppButtonText || "Rules & Regs";
      let rulesShowInAppPopUpHeader = tenantRules.rulesShowInAppPopUpHeader;
      let rulesShowInAppPopUpText = tenantRules.rulesShowInAppPopUpText;
      let rulesPopUpText = tenantRules.rulesPopUpText;
      let rulesPopUpHeader = tenantRules.rulesPopUpHeader;
      const link = tenantRules.rulesAndRegsLink;
      const rulesShowInApp = tenantRules.rulesShowInApp || false;
      if(rulesShowInApp) {
        if(!rulesShowInAppPopUpText && rulesPopUpText){
          rulesShowInAppPopUpText = rulesPopUpText;
        }
        if(!rulesShowInAppPopUpHeader && rulesPopUpHeader){
          rulesShowInAppPopUpHeader = rulesPopUpHeader;
        }
      }
      if(!this.state.userEmail){
        return (
            <Navigate to="/login"/>
        )
      } else if(!tenantVariables.doNotCollectEmail && !validateEmail(this.state.userEmail)){
        this.logout();
      } else if(tenantVariables.doNotCollectEmail && validateEmail(this.state.userEmail)){
        this.logout();
      }
      if(!verifiedAge){
        return (
            <Navigate to="/age_gate"/>
        )
      }
      if (this.state.loading === true) {
        return (
            <Loading loading={this.state.loading} backgroundImage={backgroundImage}/>
        )
      }
      let renderMainScreen = this.renderHoldingScreen()
      if(this.state.active === true && !this.state.ended && !this.state.locationChecking){
        renderMainScreen = this.renderPlayingScreen()
      }
      return(
          <div className="flex-container-home" style={{backgroundImage: "url(" + backgroundImage + ")", scrollbarWidth:"none"}}>
            {this.state.startConfetti &&
              <Confetti numberOfPieces={400} width={this.state.width} height={this.state.height} style={{zIndex: 1}}/>
            }
            {this.state.requestLoading &&
                <div className="requestLoaderBackground">
                    <div className="loader">
                        <LoadingSpinner color='#fff' loading={true} />
                    </div>
                </div>
            }
            <div className="flex-content-container-home">
              <div className="intro-container-home" style={{margin: "auto"}}>
                <div className="grid-wrapper">
                  <div className="flex-header-home" style={{marginTop:10}}>
                    <div style={{visibility: rulesShowInApp? "visible":"hidden"}}>
                      <button className="btn btn-logout" onClick={() => { rulesShowInAppPopUpText?this.toggleRules():window.open(link, '_blank') }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>{rulesInAppButtonText}</button>
                    </div>
                    <div>
                      <button className="btn btn-logout" onClick={() => { this.logout() }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>LOG OUT</button>
                    </div>
                  </div>
                  {renderMainScreen}
                </div>
              </div>
            </div>
            <Modal isOpen={this.state.modalRules} style={{width: '90%'}} id="rulesModal">
              <ModalHeader style={{color: secondaryColor, padding:10}}>
                <span style={{fontSize:25}}>
                    {rulesShowInAppPopUpHeader}
                </span>
              </ModalHeader>
              <ModalBody>
                <center className="container-out">
                  <div className="question-box question-form">
                    <p style={{width:"100%",height:200, overflow:"scroll",padding:10, borderBottom:"1px solid black", borderTop: "1px solid black", fontWeight:100, fontSize:14}} dangerouslySetInnerHTML={{ __html:rulesShowInAppPopUpText}}/>
                    <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleRules(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem'}}>Dismiss</button>
                  </div>
                </center>
              </ModalBody>
            </Modal>
          </div>
      )
    }
}

export default Main_App;
